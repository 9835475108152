@import url(../variables/color.css);
@import url(../variables/font.css);

header{
    height: 70px;
    background-color: var(--color-black);
    color: var(--color-white);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

header h1{
    font-family: var(--font-Montserrat-Regular);
    font-size: 24px;
}