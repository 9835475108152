@import url(../variables/color.css);

footer{
    position: fixed;
    height: 80px;
    background-color: var(--color-black);
    bottom: 0;
    left: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;

}

footer a {
    color: var(--color-white);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 70px;
    font-size: var(--font-size-menu);
}

.active{
    color: var(--color-gold);
}

.active .stroke-svg{
    stroke: var(--color-gold);
    fill: transparent;
}

.active .fill-svg{
    fill: var(--color-gold);
}

.search__icon{
    fill: var(--color-black);
}