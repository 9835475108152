@import url(./variables/color.css);
@import url(./variables/font.css);

.overlay_filter_hidden{
    visibility: hidden;
    opacity: 0;
}

.filter {
    position: absolute;
    left: 0;
    top: 70px;
    width: 80%;
    height: calc(100vh - 150px);
    background: var(--color-white);
    padding: 1rem;
    opacity: 0;
    z-index: 20;
    box-sizing: border-box;
    pointer-events: none;
    transition: opacity 0.5s ease-in-out;
    overflow-y: scroll;
}

.filter.open {
    display: block;
    opacity: 1;
    pointer-events: all;
}

.filter__element{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-button);
    font-family: var(--font-Montserrat-Medium);
    margin-top: 1.5rem;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    padding-bottom: 1rem;
}

summary img{
    transform: rotate(0);
    transition: .25s transform ease;
}

details[open] > summary img {
    transform: rotate(180deg);
}

.filter__emotion, .filter__movement, .filter__technical{
    margin-top: 1.5rem;
}

.filter__emotion input[type="checkbox"], .filter__movement input[type="checkbox"], .filter__technical input[type="checkbox"]{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.filter__emotion .container, .filter__movement .container, .filter__technical .container{
    height: 30px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: var(--color-white);
    border: 2px solid var(--color-gold);
    border-radius: 3px;
}

.container input:checked ~ .checkmark {
    background-color: var(--color-gold);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container input:checked ~ .checkmark:after {
    display: block;
}

.container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid var(--color-white);
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.search__collection{
    padding: 16px 8px;
}

.search__collection .filter__search{
    width: 90vw;
}

.search__collection label{
    display: flex;
    justify-content: center;
}

.long{
    width: calc((100vw / 2 ) - 16px);
}

.search__collection__art{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.long{
    padding: 8px 4px;
}
.large{
    padding: 8px 0;
}

.search__collection__art li{
    display: flex;
    align-items: center;
    justify-content: center;
}