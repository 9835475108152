@import url(./variables/color.css);
@import url(./variables/font.css);

.sr-only{
    border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
			clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

h1{
    margin: 0;
}

ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}

img{
    max-width: 100%;
}

main{
    position: relative;
    margin-bottom: 100px;
    min-height: calc(100vh - 150px);
}

main:not(.art){
    top: 70px;
    margin-bottom: 150px;
    background-color: var(--color-blue);
}

body{
    font-family: var(--font-Montserrat-Regular);
    font-size: var(--font-size-paragraph);
}

h2{
    font-size: var(--font-size-h2);
}

h3{
    font-size: var(--font-size-h3);
}

.filter__search{
    border: 2px solid var(--color-gold);
    height: 30px;
    border-radius: 50px;
    padding: 0 10px;
}

input[type=button], input[type=submit], input[type=reset] {
    background-color: var(--color-black);
    border: none;
    color: var(--color-white);
    padding: 10px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 50px;
    width: 100%;
    margin-top: 10px;
}

.burger-wrap {
    position: absolute;
    z-index: 40;
    transition: 300ms 700ms transform ease-in;
    left: 1rem;
    top: 1.5rem;
  }
  .burger-wrap.reappearBurger {
    opacity: 0;
    animation: 0.5s 2s fadeAppear ease-out forwards;
  }
  
  @keyframes fadeAppear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .burger {
    width: 30px;
    height: 27px;
    cursor: pointer;
    z-index: 30;
    transition: transform 1s ease-in;
  }

  .burger span {
    display: block;
    position: absolute;
    height: 2px;
    left: 0;
    background: var(--color-white);
    border-radius: 9px;
    opacity: 1;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .burger span:nth-child(1) {
    top: 0px;
    width: 100%;
  }
  .burger span:nth-child(2) {
    top: 10px;
    width: 100%;
  }
  .burger span:nth-child(3) {
    top: 20px;
    width: 100%;
  }
  .burger.active span:nth-child(1) {
    width: 100%;
    top: 10px;
    transform: rotate(135deg);
  }
  .burger.active span:nth-child(2) {
    opacity: 0;
  }
  .burger.active span:nth-child(3) {
    top: 10px;
    transform: rotate(-135deg);
  }

  #overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: opacity 500ms;
}

details{
  margin: 1.5rem 0;
}